import { createWritableStore } from './CreateLocalStorageStore';
import { derived, get, readable } from 'svelte/store';
import type { Readable } from 'svelte/store';
import type { IMeResponseModel } from '$lib/models/IMeResponseModel';

//Local stores derived and written from Auth0 functions

// Initialise authentication stores
const userAccountStore = createWritableStore<Partial<IMeResponseModel>>('userAccountStore', {});
const accessTokenStore = createWritableStore<string>('accessTokenStore', '');

// Configure both stores to store their values in localStorage
userAccountStore.useLocalStorage();
accessTokenStore.useLocalStorage();

const isSuperAdminStore: Readable<boolean> = derived(userAccountStore, ($userAccountStore) => {
	return $userAccountStore.roleName === 'SuperAdmin';
});

const userRole: Readable<IMeResponseModel['roleName'] | undefined> = derived(
	userAccountStore,
	($userAccountStore) => {
		return $userAccountStore.roleName ? $userAccountStore.roleName : undefined;
	}
);

export { userAccountStore, accessTokenStore, isSuperAdminStore, userRole };
