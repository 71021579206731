import { userAccountStore } from '$lib/stores/auth-store';
import { handleErrorWithSentry, replayIntegration } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';
import { get } from 'svelte/store';

const environment = import.meta.env.VITE_SENTRY_ENV as string;
const fullTracingEnvs = ['localhost', 'app.test'];

Sentry.init({
	dsn: 'https://c767ac9bffb74513930d579bc004a5c6@o1155054.ingest.us.sentry.io/6235251',
	environment,
	integrations: [replayIntegration()],
	tracesSampleRate: fullTracingEnvs.includes(environment) ? 1.0 : 0.6,
	tracePropagationTargets: ['localhost', /^https:\/\/api\.(.*\.)?smartgis\.no/],
	replaysSessionSampleRate: 0,
	replaysOnErrorSampleRate: 1.0,
	beforeSend: (event, hint) => {
		if (environment === 'localhost') {
			console.log('Sentry running on localhost, do not send events');
			//console.error(hint?.originalException || hint?.syntheticException);
			return null; // this drops the event and nothing will be sent to sentry
		}
		return event;
	}
});

Sentry.setUser({
	id: get(userAccountStore).companyName ?? 'undefined',
	username: get(userAccountStore).companyName ?? 'undefined'
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();